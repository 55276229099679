<template>
  <v-container fluid>
    <Datatable
      v-if="$_HASPERMISSION(NAME, getSession.user.id_tipo_utilizador)"
      :TableItems="TableItems"
      :TableHeaders="TableHeaders"
      :FormFields="FormFields"
      :TableActions="TableActions"
      :ModalNewText="$t(`datatables.${NAME}.ModalNewText`)"
      :ModalUpdateText="$t(`datatables.${NAME}.ModalUpdateText`)"
      :ModalDeleteText="$t(`datatables.${NAME}.ModalDeleteText`)"
      :DeleteDialogTitle="$t(`datatables.${NAME}.DeleteDialogTitle`)"
      :TableTitle="$t(`datatables.${NAME}.TableTitle`)"
      :Store="NAME"
    />
  </v-container>
</template>
<script>
import { _MODELS } from "@/store/_MAIN"
const _NAME = "PRIORIDADE"
export default {
  components: {
    Datatable: () => import("@/components/Datatable")
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    },
    TableItems() {
      return this.$store.getters[`${this.NAME}/getAll`].map(el => {
        return el
      })
    }
  },
  data: () => ({
    TableActions: _MODELS[_NAME]._ACTIONS,
    FormFields: _MODELS[_NAME]._FIELDS,
    TableHeaders: _MODELS[_NAME]._HEADERS,
    NAME: _NAME.toLowerCase()
  })
}
</script>
